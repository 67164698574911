:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index) px);
  position: fixed;
  padding: 4px;
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
}
.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}
.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}
.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}
.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0;
  }
  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }
  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }
  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}
.Toastify__toast {
  position: relative;
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  max-height: var(--toastify-toast-max-height);
  overflow: hidden;
  font-family: var(--toastify-font-family);
  cursor: pointer;
  direction: ltr;
}
.Toastify__toast--rtl {
  direction: rtl;
}
.Toastify__toast-body {
  margin: auto 0;
  flex: 1 1 auto;
  padding: 6px;
  display: flex;
  align-items: center;
}
.Toastify__toast-body > div:last-child {
  flex: 1;
}
.Toastify__toast-icon {
  -webkit-margin-end: 10px;
          margin-inline-end: 10px;
  width: 20px;
  flex-shrink: 0;
  display: flex;
}

.Toastify--animate {
  animation-fill-mode: both;
  animation-duration: 0.7s;
}

.Toastify--animate-icon {
  animation-fill-mode: both;
  animation-duration: 0.3s;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast {
    margin-bottom: 0;
    border-radius: 0;
  }
}
.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}
.Toastify__toast-theme--light {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}
.Toastify__toast-theme--colored.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}
.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}
.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}
.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}
.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}
.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}
.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}
.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  align-self: flex-start;
}
.Toastify__close-button--light {
  color: #000;
  opacity: 0.3;
}
.Toastify__close-button > svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}
.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}
.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: var(--toastify-z-index);
  opacity: 0.7;
  transform-origin: left;
}
.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear 1 forwards;
}
.Toastify__progress-bar--controlled {
  transition: transform 0.2s;
}
.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: right;
}

.Toastify__spinner {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 2px solid;
  border-radius: 100%;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  animation: Toastify__spin 0.65s linear infinite;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}
@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }
  75% {
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    transform: translate3d(5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}
@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  75% {
    transform: translate3d(0, 10px, 0);
  }
  90% {
    transform: translate3d(0, -5px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}
@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }
  75% {
    transform: translate3d(0, -10px, 0);
  }
  90% {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}
.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}
.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}
.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}
.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}
.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}
.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}
.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    transform: perspective(400px);
  }
}
@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }
  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInLeft {
  from {
    transform: translate3d(-110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInDown {
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}
@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}
@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}
@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}
.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}
.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}
.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}
.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
}
.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
}
.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}
.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

@keyframes Toastify__spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*# sourceMappingURL=ReactToastify.css.map */
/** handle the notification color and the text color based on the theme **/
.Toastify__toast-theme--dark {
  font-family: Montserrat, serif;
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.Toastify__toast-theme--light {
  font-family: Montserrat, serif;
  --tw-bg-opacity: 1;
  background-color: rgb(24 41 85 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  font-family: Montserrat, serif;
  --tw-bg-opacity: 1;
  background-color: rgb(1 87 155 / var(--tw-bg-opacity));
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  font-family: Montserrat, serif;
  --tw-bg-opacity: 1;
  background-color: rgb(27 94 32 / var(--tw-bg-opacity));
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  font-family: Montserrat, serif;
  --tw-bg-opacity: 1;
  background-color: rgb(230 81 0 / var(--tw-bg-opacity));
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  font-family: Montserrat, serif;
  --tw-bg-opacity: 1;
  background-color: rgb(198 40 40 / var(--tw-bg-opacity));
}

.Toastify__progress-bar{
  --tw-bg-opacity: 1;
  background-color: rgb(205 69 255 / var(--tw-bg-opacity));
}

.Toastify__progress-bar--rtl{
  --tw-bg-opacity: 1;
  background-color: rgb(205 69 255 / var(--tw-bg-opacity));
}

.Toastify__progress-bar-theme--light{
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}

.Toastify__progress-bar-theme--dark{
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.Toastify__progress-bar--info{
  --tw-bg-opacity: 1;
  background-color: rgb(1 87 155 / var(--tw-bg-opacity));
}

.Toastify__progress-bar--success{
  --tw-bg-opacity: 1;
  background-color: rgb(27 94 32 / var(--tw-bg-opacity));
}

.Toastify__progress-bar--warning{
  --tw-bg-opacity: 1;
  background-color: rgb(230 81 0 / var(--tw-bg-opacity));
}

.Toastify__progress-bar--error{
  --tw-bg-opacity: 1;
  background-color: rgb(198 40 40 / var(--tw-bg-opacity));
}

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button{
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

/**
 * For MUI/Tailwind CSS interoperability, see https://mui.com/guides/interoperability/#tailwind-css
 */

*, ::before, ::after{
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}
.container{
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 2rem;
  padding-left: 2rem;
}
@media (min-width: 100%){

  .container{
    max-width: 100%;
  }
}
@media (min-width: 1000px){

  .container{
    max-width: 1000px;
  }
}
@media (min-width: 1200px){

  .container{
    max-width: 1200px;
  }
}
.visible{
  visibility: visible !important;
}
.static{
  position: static !important;
}
.fixed{
  position: fixed !important;
}
.absolute{
  position: absolute !important;
}
.relative{
  position: relative !important;
}
.right-8{
  right: 2rem !important;
}
.top-9{
  top: 2.25rem !important;
}
.top-1\/2{
  top: 50% !important;
}
.left-1\/2{
  left: 50% !important;
}
.right-4{
  right: 1rem !important;
}
.right-0{
  right: 0px !important;
}
.z-20{
  z-index: 20 !important;
}
.z-10{
  z-index: 10 !important;
}
.m-\[9px\]{
  margin: 9px !important;
}
.m-0{
  margin: 0px !important;
}
.my-8{
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}
.my-16{
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}
.my-0{
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.my-6{
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.my-4{
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.my-2{
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.mb-4{
  margin-bottom: 1rem !important;
}
.mb-8{
  margin-bottom: 2rem !important;
}
.ml-4{
  margin-left: 1rem !important;
}
.ml-1{
  margin-left: 0.25rem !important;
}
.mt-6{
  margin-top: 1.5rem !important;
}
.mb-1{
  margin-bottom: 0.25rem !important;
}
.mt-12{
  margin-top: 3rem !important;
}
.mt-16{
  margin-top: 4rem !important;
}
.mt-2{
  margin-top: 0.5rem !important;
}
.mt-5{
  margin-top: 1.25rem !important;
}
.ml-6{
  margin-left: 1.5rem !important;
}
.mb-2{
  margin-bottom: 0.5rem !important;
}
.ml-8{
  margin-left: 2rem !important;
}
.mb-6{
  margin-bottom: 1.5rem !important;
}
.mt-8{
  margin-top: 2rem !important;
}
.mr-1{
  margin-right: 0.25rem !important;
}
.mr-2{
  margin-right: 0.5rem !important;
}
.mb-3{
  margin-bottom: 0.75rem !important;
}
.-mt-\[16px\]{
  margin-top: -16px !important;
}
.-mb-\[8px\]{
  margin-bottom: -8px !important;
}
.mt-0{
  margin-top: 0px !important;
}
.mt-\[16px\]{
  margin-top: 16px !important;
}
.mb-\[8px\]{
  margin-bottom: 8px !important;
}
.mt-4{
  margin-top: 1rem !important;
}
.mb-5{
  margin-bottom: 1.25rem !important;
}
.block{
  display: block !important;
}
.inline-block{
  display: inline-block !important;
}
.inline{
  display: inline !important;
}
.flex{
  display: flex !important;
}
.inline-flex{
  display: inline-flex !important;
}
.table{
  display: table !important;
}
.grid{
  display: grid !important;
}
.hidden{
  display: none !important;
}
.aspect-video{
  aspect-ratio: 16 / 9 !important;
}
.aspect-square{
  aspect-ratio: 1 / 1 !important;
}
.h-4{
  height: 1rem !important;
}
.h-min{
  height: -moz-min-content !important;
  height: min-content !important;
}
.h-6{
  height: 1.5rem !important;
}
.h-10{
  height: 2.5rem !important;
}
.h-full{
  height: 100% !important;
}
.h-8{
  height: 2rem !important;
}
.h-max{
  height: -moz-max-content !important;
  height: max-content !important;
}
.min-h-full{
  min-height: 100% !important;
}
.min-h-\[100vh\]{
  min-height: 100vh !important;
}
.min-h-screen{
  min-height: 100vh !important;
}
.w-full{
  width: 100% !important;
}
.w-6{
  width: 1.5rem !important;
}
.w-56{
  width: 14rem !important;
}
.w-5\/12{
  width: 41.666667% !important;
}
.w-1\/3{
  width: 33.333333% !important;
}
.w-4{
  width: 1rem !important;
}
.w-2\/3{
  width: 66.666667% !important;
}
.w-11\/12{
  width: 91.666667% !important;
}
.w-48{
  width: 12rem !important;
}
.w-3\/4{
  width: 75% !important;
}
.w-32{
  width: 8rem !important;
}
.w-1\/2{
  width: 50% !important;
}
.w-1\/4{
  width: 25% !important;
}
.w-fit{
  width: -moz-fit-content !important;
  width: fit-content !important;
}
.min-w-min{
  min-width: -moz-min-content !important;
  min-width: min-content !important;
}
.min-w-0{
  min-width: 0px !important;
}
.flex-none{
  flex: none !important;
}
.flex-1{
  flex: 1 1 0% !important;
}
.flex-shrink{
  flex-shrink: 1 !important;
}
.shrink{
  flex-shrink: 1 !important;
}
.\!shrink{
  flex-shrink: 1 !important;
}
.flex-grow{
  flex-grow: 1 !important;
}
.basis-\[60\%\]{
  flex-basis: 60% !important;
}
.basis-\[25\%\]{
  flex-basis: 25% !important;
}
.basis-\[15\%\]{
  flex-basis: 15% !important;
}
.-translate-x-1\/2{
  --tw-translate-x: -50% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.-translate-y-1\/2{
  --tw-translate-y: -50% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.translate-y-0\.5{
  --tw-translate-y: 0.125rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.translate-y-0{
  --tw-translate-y: 0px !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.transform{
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
@keyframes spin{

  to{
    transform: rotate(360deg);
  }
}
.animate-spin{
  animation: spin 1s linear infinite !important;
}
.cursor-pointer{
  cursor: pointer !important;
}
.cursor-not-allowed{
  cursor: not-allowed !important;
}
.list-decimal{
  list-style-type: decimal !important;
}
.grid-cols-2{
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}
.flex-col{
  flex-direction: column !important;
}
.items-center{
  align-items: center !important;
}
.items-stretch{
  align-items: stretch !important;
}
.justify-end{
  justify-content: flex-end !important;
}
.justify-center{
  justify-content: center !important;
}
.justify-between{
  justify-content: space-between !important;
}
.justify-around{
  justify-content: space-around !important;
}
.gap-2{
  gap: 0.5rem !important;
}
.gap-4{
  gap: 1rem !important;
}
.gap-16{
  gap: 4rem !important;
}
.gap-6{
  gap: 1.5rem !important;
}
.gap-1{
  gap: 0.25rem !important;
}
.gap-3{
  gap: 0.75rem !important;
}
.space-x-5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
}
.space-y-4 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(1rem * var(--tw-space-y-reverse)) !important;
}
.space-y-6 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse)) !important;
}
.space-x-3 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
}
.space-y-2 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse)) !important;
}
.space-x-2 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}
.space-x-10 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(2.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}
.overflow-hidden{
  overflow: hidden !important;
}
.overflow-scroll{
  overflow: scroll !important;
}
.overflow-y-scroll{
  overflow-y: scroll !important;
}
.overscroll-none{
  overscroll-behavior: none !important;
}
.whitespace-nowrap{
  white-space: nowrap !important;
}
.rounded-full{
  border-radius: 9999px !important;
}
.rounded{
  border-radius: 0.25rem !important;
}
.rounded-b-xl{
  border-bottom-right-radius: 0.75rem !important;
  border-bottom-left-radius: 0.75rem !important;
}
.rounded-tr-2xl{
  border-top-right-radius: 1rem !important;
}
.rounded-tl-2xl{
  border-top-left-radius: 1rem !important;
}
.border-\[1px\]{
  border-width: 1px !important;
}
.border-2{
  border-width: 2px !important;
}
.border-b-2{
  border-bottom-width: 2px !important;
}
.border-solid{
  border-style: solid !important;
}
.border-white{
  --tw-border-opacity: 1 !important;
  border-color: rgb(255 255 255 / var(--tw-border-opacity)) !important;
}
.border-opacity-70{
  --tw-border-opacity: 0.7 !important;
}
.bg-black{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity)) !important;
}
.bg-primary{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(205 69 255 / var(--tw-bg-opacity)) !important;
}
.bg-neutral{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(24 41 85 / var(--tw-bg-opacity)) !important;
}
.bg-white{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}
.bg-background{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity)) !important;
}
.bg-teal-500{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(20 184 166 / var(--tw-bg-opacity)) !important;
}
.bg-teal-700{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(15 118 110 / var(--tw-bg-opacity)) !important;
}
.bg-slate-800{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity)) !important;
}
.bg-secondary{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(68 56 202 / var(--tw-bg-opacity)) !important;
}
.bg-opacity-30{
  --tw-bg-opacity: 0.3 !important;
}
.bg-opacity-40{
  --tw-bg-opacity: 0.4 !important;
}
.bg-opacity-0{
  --tw-bg-opacity: 0 !important;
}
.bg-gradient-to-br{
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops)) !important;
}
.from-background{
  --tw-gradient-from: #0F172A !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(15 23 42 / 0)) !important;
}
.to-neutral{
  --tw-gradient-to: #182955 !important;
}
.p-14{
  padding: 3.5rem !important;
}
.p-0{
  padding: 0px !important;
}
.p-3{
  padding: 0.75rem !important;
}
.p-2{
  padding: 0.5rem !important;
}
.p-8{
  padding: 2rem !important;
}
.p-4{
  padding: 1rem !important;
}
.py-6{
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
.px-4{
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.py-4{
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.py-3{
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}
.py-8{
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}
.px-3{
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}
.py-1{
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
.px-6{
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
.px-10{
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}
.pb-4{
  padding-bottom: 1rem !important;
}
.pt-2{
  padding-top: 0.5rem !important;
}
.pl-4{
  padding-left: 1rem !important;
}
.pb-5{
  padding-bottom: 1.25rem !important;
}
.text-center{
  text-align: center !important;
}
.align-baseline{
  vertical-align: baseline !important;
}
.align-middle{
  vertical-align: middle !important;
}
.font-mono{
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}
.text-lg{
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
}
.text-5xl{
  font-size: 3rem !important;
  line-height: 1 !important;
}
.text-3xl{
  font-size: 1.875rem !important;
  line-height: 2.25rem !important;
}
.text-2xl{
  font-size: 1.5rem !important;
  line-height: 2rem !important;
}
.text-4xl{
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
}
.text-xs{
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}
.text-sm{
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}
.font-bold{
  font-weight: 700 !important;
}
.font-black{
  font-weight: 900 !important;
}
.font-semibold{
  font-weight: 600 !important;
}
.font-extrabold{
  font-weight: 800 !important;
}
.uppercase{
  text-transform: uppercase !important;
}
.lowercase{
  text-transform: lowercase !important;
}
.italic{
  font-style: italic !important;
}
.leading-7{
  line-height: 1.75rem !important;
}
.leading-none{
  line-height: 1 !important;
}
.text-gray-300{
  --tw-text-opacity: 1 !important;
  color: rgb(209 213 219 / var(--tw-text-opacity)) !important;
}
.text-highlight{
  --tw-text-opacity: 1 !important;
  color: rgb(94 234 212 / var(--tw-text-opacity)) !important;
}
.text-white{
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}
.text-secondary{
  --tw-text-opacity: 1 !important;
  color: rgb(68 56 202 / var(--tw-text-opacity)) !important;
}
.text-primary{
  --tw-text-opacity: 1 !important;
  color: rgb(205 69 255 / var(--tw-text-opacity)) !important;
}
.text-warning{
  --tw-text-opacity: 1 !important;
  color: rgb(230 81 0 / var(--tw-text-opacity)) !important;
}
.text-success{
  --tw-text-opacity: 1 !important;
  color: rgb(27 94 32 / var(--tw-text-opacity)) !important;
}
.text-gray-500{
  --tw-text-opacity: 1 !important;
  color: rgb(107 114 128 / var(--tw-text-opacity)) !important;
}
.underline{
  -webkit-text-decoration-line: underline !important;
          text-decoration-line: underline !important;
}
.no-underline{
  -webkit-text-decoration-line: none !important;
          text-decoration-line: none !important;
}
.decoration-white{
  -webkit-text-decoration-color: #fff !important;
          text-decoration-color: #fff !important;
}
.decoration-1{
  text-decoration-thickness: 1px !important;
}
.underline-offset-2{
  text-underline-offset: 2px !important;
}
.underline-offset-8{
  text-underline-offset: 8px !important;
}
.opacity-60{
  opacity: 0.6 !important;
}
.opacity-20{
  opacity: 0.2 !important;
}
.shadow-none{
  --tw-shadow: 0 0 #0000 !important;
  --tw-shadow-colored: 0 0 #0000 !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.blur{
  --tw-blur: blur(8px) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}
.blur-md{
  --tw-blur: blur(12px) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}
.filter{
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}
.transition-all{
  transition-property: all !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}

/**
 * Typography
 * ==========
 */
p {
  margin-top: 0;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
}

a{
  --tw-text-opacity: 1;
  color: rgb(205 69 255 / var(--tw-text-opacity));
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
}

strong{
  --tw-text-opacity: 1;
  color: rgb(205 69 255 / var(--tw-text-opacity));
}

/**
 * Custom scrollbar
 * ================
 */

/* width */
::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

/* Track */
::-webkit-scrollbar-track{
  --tw-bg-opacity: 1;
  background-color: rgb(24 41 85 / var(--tw-bg-opacity));
}

/* Handle */
::-webkit-scrollbar-thumb{
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-bg-opacity: 0.1;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(68 56 202 / var(--tw-bg-opacity));
}
.hover\:text-highlight:hover{
  --tw-text-opacity: 1 !important;
  color: rgb(94 234 212 / var(--tw-text-opacity)) !important;
}
.hover\:opacity-100:hover{
  opacity: 1 !important;
}
@media (min-width: 640px){

  .sm\:flex-row{
    flex-direction: row !important;
  }
}
@media (min-width: 768px){

  .md\:my-12{
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .md\:mb-8{
    margin-bottom: 2rem !important;
  }

  .md\:mt-4{
    margin-top: 1rem !important;
  }

  .md\:block{
    display: block !important;
  }

  .md\:flex{
    display: flex !important;
  }

  .md\:hidden{
    display: none !important;
  }

  .md\:aspect-\[16\/9\]{
    aspect-ratio: 16 / 9 !important;
  }

  .md\:w-1\/4{
    width: 25% !important;
  }

  .md\:w-1\/3{
    width: 33.333333% !important;
  }

  .md\:w-\[400px\]{
    width: 400px !important;
  }

  .md\:w-2\/3{
    width: 66.666667% !important;
  }

  .md\:grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .md\:flex-row{
    flex-direction: row !important;
  }

  .md\:gap-2{
    gap: 0.5rem !important;
  }

  .md\:py-12{
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .md\:py-8{
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .md\:px-20{
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .md\:pb-8{
    padding-bottom: 2rem !important;
  }

  .md\:text-5xl{
    font-size: 3rem !important;
    line-height: 1 !important;
  }

  .md\:text-lg{
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
  }

  .md\:text-4xl{
    font-size: 2.25rem !important;
    line-height: 2.5rem !important;
  }
}
@media (min-width: 1024px){

  .lg\:my-5{
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .lg\:ml-24{
    margin-left: 6rem !important;
  }

  .lg\:ml-12{
    margin-left: 3rem !important;
  }

  .lg\:block{
    display: block !important;
  }

  .lg\:inline{
    display: inline !important;
  }

  .lg\:flex{
    display: flex !important;
  }

  .lg\:hidden{
    display: none !important;
  }

  .lg\:w-3\/4{
    width: 75% !important;
  }

  .lg\:w-1\/3{
    width: 33.333333% !important;
  }

  .lg\:w-1\/2{
    width: 50% !important;
  }

  .lg\:w-5\/6{
    width: 83.333333% !important;
  }

  .lg\:w-auto{
    width: auto !important;
  }

  .lg\:flex-1{
    flex: 1 1 0% !important;
  }

  .lg\:flex-shrink{
    flex-shrink: 1 !important;
  }

  .lg\:flex-grow{
    flex-grow: 1 !important;
  }

  .lg\:flex-row{
    flex-direction: row !important;
  }

  .lg\:items-center{
    align-items: center !important;
  }

  .lg\:items-baseline{
    align-items: baseline !important;
  }

  .lg\:justify-between{
    justify-content: space-between !important;
  }

  .lg\:gap-7{
    gap: 1.75rem !important;
  }

  .lg\:space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--tw-space-y-reverse)) !important;
  }

  .lg\:space-x-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:py-8{
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .lg\:px-32{
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .lg\:px-64{
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .lg\:pt-0\.5{
    padding-top: 0.125rem !important;
  }

  .lg\:pt-0{
    padding-top: 0px !important;
  }

  .lg\:text-base{
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }
}

