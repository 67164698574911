/**
 * For MUI/Tailwind CSS interoperability, see https://mui.com/guides/interoperability/#tailwind-css
 */
@import 'vendor/react-toastify.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

/**
 * Typography
 * ==========
 */
p {
  margin-top: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}

a {
  @apply text-primary no-underline;
}

strong {
  @apply text-primary;
}

/**
 * Custom scrollbar
 * ================
 */

/* width */
::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

/* Track */
::-webkit-scrollbar-track {
  @apply bg-neutral;
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-white bg-opacity-10;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  @apply bg-secondary;
}
